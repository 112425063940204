import {lazy, useEffect, useMemo, useState} from 'react';

import './App.css';
import "@material-tailwind/react/tailwind.css";
import 'tw-elements';

import { 
  Route,
  Routes,
  Navigate,
  useLocation
} from 'react-router-dom';

import { ConfigurationService } from './services/configurationService/ConfigurationService';
import { NotificationService } from './services/notificationService/NotificationService';
import { FileService } from './services/fileService/FileService';
import { ConsumerService } from './services/consumerService/ConsumerService';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { userDataSelector} from './recoil/user';
import { useRecoilValue } from 'recoil';
import LoadingScreen from './components/loadingScreen/LoadingScreen';
import { UserManagementService } from './services/UserManagementService/UserManagementService';

const MainLayout = lazy(() => import('./containers/mainLayout/MainLayout'));
const Login = lazy(() => import('./containers/login/Login'));
const CompleteRegistration = lazy(() => import('./containers/completeRegistration/CompleteRegistration'));
const Page = lazy(() => import('./containers/page/Page'));


function App() {
  const userData = useRecoilValue(userDataSelector);
  const [appIsLoaded, setAppIsLoaded] = useState(false);
  const [appIsUsingPublicRouting, setAppIsUsingPublicRouting] = useState(false);

  useEffect(() => {
    const ENVIRONMENT = process.env.REACT_APP_ENV.toUpperCase();
    console.log("file: App.js ~ line 38 ~ useEffect ~ ENVIRONMENT", ENVIRONMENT);
    if (["PROD", "STAGING", "DEV"].includes(ENVIRONMENT)){
      Sentry.init({
        dsn: "https://aeb57e6a2faf49bf8114d26bcb37a203@o1124859.ingest.sentry.io/6163154",
        integrations: [new Integrations.BrowserTracing()],
        environment: ENVIRONMENT,
      
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.6,
      });
    }
  }, []);

  useEffect(() => {
    if (!userData) return;

    const {institution} = userData;
    if (institution) {
      (async () => {
        await ConfigurationService.init();
        const {endpointsIds} = await ConfigurationService.getConfig();
        NotificationService.init({gatewayId: endpointsIds.notifications});
        FileService.init({gatewayId: endpointsIds.file});
        ConsumerService.init({gatewayId: endpointsIds.consumer});
        UserManagementService.init({gatewayId: process.env.REACT_APP_USER_MANAGEMENT_GATEWAY_ID});
        setAppIsLoaded(true);
      })();
    }
  },[userData]);

  const publicRoutes = useMemo(() => {
    const RedirectToLoginComponent = () => {
      const location = useLocation()
      const callbackURL = encodeURI(`${location.pathname}${location.search}`)
      
      return (
        <Navigate to={`/login?callbackUrl=${callbackURL}`}/>
      )
    }
    return (
      <>
        <Route path="/login" element={ <Login/> }/>
        <Route path="/registration" element={<CompleteRegistration />}/>
        <Route path="*" element={<RedirectToLoginComponent/>}/>
      </>
    )
  }, []);

  const protectedRoutes = useMemo(() => {
    return (
      <>
        <Route path="/" element={<MainLayout/>}>
          <Route path="/" element={<Page title="Tablou de control" component="ControlPanel"/>}/>
          <Route path="/notificari/adauga" element={<Page title="Notificare nouă" component="AddNewNotification"/>}/>
          <Route path="/notificari/revizuire" element={<Page title="Revizuire notificări" component="ReviewNotifications"/>}/>
          <Route path="/galerie" element={<Page title="Galerie" component="Galerie"/>}/>
          <Route path="/colegi" element={<Page title="Colegi" component="Colegi"/>}/>
          <Route path="/colegi/:userId" element={<Page title="Editare utilizator" component="EditUser"/>}/>
        </Route>
        <Route path="/login" element={<Navigate to="/"/>}/>
        <Route path="/registration" element={<Navigate to="/"/>}/>
      </>
    )
  }, []);

  const routes = useMemo(() => {
    
    if (userData) {
      setAppIsUsingPublicRouting(false);
      return protectedRoutes;
    } 
    setAppIsUsingPublicRouting(true);
    return publicRoutes;
  }, [userData, publicRoutes, protectedRoutes])

  if (appIsUsingPublicRouting || appIsLoaded) {
    return (
      <div className="Primaria-Mea-App max-w-full">
        <Sentry.ErrorBoundary fallback={<p>A aparut o eroare, va rugam sa re-incarcati aplicatia in pagina.</p>}>
          <Routes>
              {routes}
          </Routes>
        </Sentry.ErrorBoundary>;
      </div>
    );
  }

  return <LoadingScreen/>
}

export default App;
