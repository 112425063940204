import BaseAPIService from "../baseAPIService/BaseAPIService";

class UserManagement extends BaseAPIService{
    getUserList = async () => {
        await this._preRequest();
        const users = await this.axios({
            method: 'GET',
            url: '/users'
        });

        return users.data;
    }

    getUserById = async (id) => {
        await this._preRequest();
        const users = await this.axios({
            method: 'GET',
            url: `/users/${id}`
        });

        return users.data;
    }

    getAvailablePermissionsList = async () => {
        await this._preRequest();

        const availablePermissionsList = await this.axios({
            method: 'GET',
            url: '/abac/list/available'
        });

        return availablePermissionsList.data.data;
    }

    updateUserPermissions = async (id, newPermissions) => {
        await this._preRequest();

        await this.axios({
            method: 'POST',
            url: `/abac/update/${id}`,
            data: newPermissions
        });
    }

    createUser = async (email, username) => {
        await this._preRequest();

        return await this.axios({
            url: '/users/create',
            method: 'POST',
            data: {
                email,
                username
            }
        })
    }
}

export const UserManagementService = new UserManagement();