import axios from "axios";
import { AuthService } from "../auth/auth";

class Notification {
    constructor() {
        this.initialized = false;
    }
    init = ({gatewayId}) => {
        if (this.initialized) return;

        this.gatewayId = gatewayId;
        this._createAxiosInstance();

        this.initialized = true;
    }

    _createAxiosInstance = () => {
        const gatewayENV = process.env.REACT_APP_CONFIG_GATEWAY_ENV;
        const gatewayId = this.gatewayId;
        if (!gatewayId) {
            throw new Error('Missing gatewayId', gatewayId);
        }
        this.axios = axios.create({
            baseURL: `https://${gatewayId}.execute-api.eu-central-1.amazonaws.com/${gatewayENV}`,
            timeout: 5000
        });
    }
    _preRequest = async () => {
        const tokens = await AuthService.getLoggedInUserTokens();
        if (!tokens) return; //TODO initiate logout
        
        const {AccessToken} = tokens;
        if (!this.axios) {
            this._createAxiosInstance();
        }
        console.log('!! AccessToken', AccessToken);
        this.axios.defaults.headers.common['Authorization'] = AccessToken;
    }

    _parseNotification = (notificationObj) => {
        try {
            const parsedImageUrls = JSON.parse(notificationObj.imageUrls)

            return {
                ...notificationObj,
                imageUrls: parsedImageUrls
            }
        } catch (error) {
            return notificationObj;
        }
        
    }

    sendNotification = async (data) => {
        await this._preRequest();
        return await this.axios({
                            method: 'POST',
                            url: '/notification',
                            data
                        });
    }

    getNotificationsByDate = async (starting, status) => {
        //TODO add filters
        await this._preRequest();
        const response = await this.axios({
            method: 'GET',
            url: '/notifications',
            params: {
                starting,
                status
            }
        });

        return response.data.data.map(notificationObj => this._parseNotification(notificationObj));
    }

    getNotificationById = async (id) => {
        await this._preRequest();
        const response = await this.axios({
            method: 'GET',
            url: `/notification/${id}`
        });
        return this._parseNotification(response.data.data);
    }

    approveNotification = async (id) => {
        this._preRequest();

        return this.axios({
            method: 'PUT',
            url: `/notification/${id}`,
            data: {
                status: "APPROVED"
            }
        })
    }

    saveTemplate = async (data) => {
        await this._preRequest();
        
        try {
            const response = await this.axios({
                method: 'POST',
                url: `/templates`,
                data
            });
    
            return response.data.data;
        } catch (error) {
            throw new Error(error);
        }
    }

    removeTemplate = async (id) => {
        await this._preRequest();
        
        try {
            const response = await this.axios({
                method: 'DELETE',
                url: `/templates/${id}`
            });
    
            return response.data.messate;
        } catch (error) {
            throw new Error(error);
        }
    }

    getAllTemplates = async () => {
        await this._preRequest();

        try {
            const response = await this.axios({
                method: 'GET',
                url: `/templates`
            });
    
            return response.data.data;
        } catch (error) {
            throw new Error(error);
        }
    }
}


export const NotificationService = new Notification();