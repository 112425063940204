import {atom, selector} from 'recoil'
import { AuthService } from '../services/auth/auth';


// export const userTokensAtom = atom({
//     key: 'user-tokens',
//     default: null
// })

// export const userTokensSelector = selector({
//     key: 'user-token-selector',
//     get: async ({get}) => {
//         let currentTokens = get(userTokensAtom);
//         if (!currentTokens) {
//             currentTokens = AuthService.getLoggedInUserTokens();
//         }
        
//         return currentTokens;
//     },
//     set: ({set, newValue}) => {
//         set(userTokensAtom, newValue);
//     }
// });

export const userDataAtom = atom({
    key: 'user-data',
    default: null
})

function parseJwt (token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

const DATA_KEY_MAPS = {
    'name': 'name',
    'family_name': 'family_name',
    'cognito:username': 'id',
    'custom:client': 'institution',
    'preferred_username': 'preferred_username',
    'email': 'email',
    'abac': 'permissions'
}

export const userDataSelector = selector({
    key: 'user-data-selector',
    get:  async ({get}) => {
        
        let userData = get(userDataAtom);
        console.log("file: user.js ~ line 55 ~ get: ~ userData", userData);

        if (userData) return userData;

        const tokens = await AuthService.getLoggedInUserTokens();
        console.log("file: user.js ~ line 60 ~ get: ~ tokens", tokens);

        if (!tokens) return null;

        const IdToken = tokens.IdToken;

        const data = parseJwt(IdToken);

        userData = Object.keys(data)
            .filter(key => Object.keys(DATA_KEY_MAPS).includes(key))
            .reduce((obj, key) => {
                if (key === 'abac') { //permissions
                    try {
                        obj[DATA_KEY_MAPS[key]] = JSON.parse(data[key]);
                    } catch (error) {
                        console.log("file: user.js ~ line 80 ~ .reduce ~ error", error);
                        obj[DATA_KEY_MAPS[key]] = data[key];
                    }
                } else {
                    obj[DATA_KEY_MAPS[key]] = data[key];
                }
            return obj;
            }, {});

            console.log("file: user.js ~ line 86 ~ get: ~ userData", userData);
        return userData;
    },
    set: ({set}, tokens) => {
        if (!tokens) return null;

        const IdToken = tokens.IdToken;

        const data = parseJwt(IdToken);

        const userData = Object.keys(data)
                .filter(key => Object.keys(DATA_KEY_MAPS).includes(key))
                .reduce((obj, key) => {
                obj[DATA_KEY_MAPS[key]] = data[key];
                return obj;
                }, {});
        try {
            userData.permissions = JSON.parse(userData.permissions);
        } catch (error) {
            console.log("file: user.js ~ line 100 ~ error", error);  
        }

        set(userDataAtom, userData);
    }
});

