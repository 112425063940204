import axios from "axios";
import { AuthService } from "../auth/auth";

class BaseAPIService {
    constructor() {
        this.initialized = false;
    }
    init = ({gatewayId}) => {
        if (this.initialized) return;

        this.gatewayId = gatewayId;
        this._createAxiosInstance();

        this.initialized = true;
    }

    _createAxiosInstance = () => {
        const gatewayENV = process.env.REACT_APP_CONFIG_GATEWAY_ENV;
        const gatewayId = this.gatewayId;
        if (!gatewayId) {
            throw new Error('Missing gatewayId', gatewayId);
        }
        this.axios = axios.create({
            baseURL: `https://${gatewayId}.execute-api.eu-central-1.amazonaws.com/${gatewayENV}`,
            timeout: 5000
        });
    }
    _preRequest = async () => {
        const tokens = await AuthService.getLoggedInUserTokens();
        if (!tokens) return; //TODO initiate logout
        
        const {AccessToken} = tokens;
        if (!this.axios) {
            this._createAxiosInstance();
        }
        this.axios.defaults.headers.common['Authorization'] = AccessToken;
    }
}

export default BaseAPIService;