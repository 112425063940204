import BaseAPIService from "../baseAPIService/BaseAPIService";
import { AuthService } from "../auth/auth";

class ConsumerServiceClass extends BaseAPIService {
    getActiveUsersCount = async () => {
        const tokens = await AuthService.getLoggedInUserTokens();
        if (!tokens) return;
        
        const {IdToken} = tokens;
        await this._preRequest();

        const response = await this.axios({
            method: 'GET',
            url: '/activeUserCount'
        });

        
        return response.data.data
    }
}

export const ConsumerService = new ConsumerServiceClass();