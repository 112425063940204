import 'cross-fetch/polyfill';


import { CognitoIdentityProviderClient, InitiateAuthCommand, RespondToAuthChallengeCommand } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import
import ls from 'localstorage-slim';

const {REACT_APP_USER_POOL_ID, REACT_APP_CLIENT_ID, REACT_APP_REGION} = process.env;

const poolData = {
    UserPoolId: REACT_APP_USER_POOL_ID,
    ClientId: REACT_APP_CLIENT_ID,
    region: REACT_APP_REGION
}
const ACCESS_TOKEN_LS_KEY = 'PMI-AT';
const REFRESH_TOKEN_LS_KEY = 'PMI-RT';
const ID_TOKEN_LS_KEY = 'PMI-IDT';

class Authentication {
    constructor() {
        this.userPoolClient = new CognitoIdentityProviderClient(poolData);
        this.requiredUserAttributes = null;
    }

    _saveTokens = (AuthenticationResult) => {
        if (!AuthenticationResult) return ;

        const {ExpiresIn , AccessToken, RefreshToken, IdToken} = AuthenticationResult;
        const TTLTimeSeconds = ExpiresIn - 30;

        if (AccessToken) {
            ls.set(ACCESS_TOKEN_LS_KEY, AccessToken, {ttl: TTLTimeSeconds});
        }
        if (RefreshToken) {
            ls.set(REFRESH_TOKEN_LS_KEY, RefreshToken, {ttl: 32400 }); // 9 hours
        }

        if (IdToken) {
            ls.set(ID_TOKEN_LS_KEY, IdToken, {ttl: TTLTimeSeconds});
        }
    
    }

    _removeTokens = () => {
        ls.remove(REFRESH_TOKEN_LS_KEY);
        ls.remove(ACCESS_TOKEN_LS_KEY);
        ls.remove(ID_TOKEN_LS_KEY);
    }

    getLoggedInUserTokens = async () => {
        const AccessToken = ls.get(ACCESS_TOKEN_LS_KEY);
        const RefreshToken = ls.get(REFRESH_TOKEN_LS_KEY);
        const IdToken = ls.get(ID_TOKEN_LS_KEY);
        
        if (AccessToken && IdToken) {
            return {AccessToken, IdToken};
        } 

        if (RefreshToken) {
            const response = await this.loginByRefreshToken(RefreshToken);
            this._saveTokens(response.AuthenticationResult);
            return await this.getLoggedInUserTokens();
        }
    }

    loginByRefreshToken = async (RefreshToken) => {
        const response = await this.userPoolClient.send(new InitiateAuthCommand({
            AuthFlow: 'REFRESH_TOKEN_AUTH',
            ClientId: poolData.ClientId,
            AuthParameters: {
                REFRESH_TOKEN: RefreshToken
            },
            Region: 'eu-central-1'
        }))
        console.log("file: auth.js ~ line 56 ~ Authentication ~ loginByRefreshTokenRefreshToken ~ response", response);
        return response;
    }

    login(username, password) {
        return new Promise(async (resolve, reject) => {
            if (!username || !password) {
                reject(new Error('missing username or password'));
            }

            try {
                const response = await this.userPoolClient.send(new InitiateAuthCommand({
                    AuthFlow: 'USER_PASSWORD_AUTH',
                    ClientId: poolData.ClientId,
                    AuthParameters: {
                        USERNAME: username,
                        PASSWORD: password
                    },
                    Region: 'eu-central-1'
                }))

                //save token to LS
                if (response.AuthenticationResult) {
                    this._saveTokens(response.AuthenticationResult);
                    resolve(response);
                    return;
                }

                if (response.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                    const ChallengeParameters = response.ChallengeParameters;
                    try {
                        const requiredAttributes = JSON.parse(ChallengeParameters.requiredAttributes);
                        this.requiredUserAttributes = requiredAttributes.map((userAttribute) => {
                            return userAttribute.split('.')[1];
                        });
                        const userAttributes = JSON.parse(ChallengeParameters.userAttributes);
                        const filteredUserAttributes = Object.keys(userAttributes)
                                                    .filter(key => key !== 'email_verified')
                                                    .reduce((obj, key) => {
                                                    obj[key] = userAttributes[key];
                                                    return obj;
                                                    }, {});
                        resolve({newPasswordRequired: true, userAttributes: filteredUserAttributes, SESSION: response.Session});
                    } catch (error) {
                        console.log("file: auth.js ~ line 117 ~ Authentication ~ returnnewPromise ~ error", error);
                        
                    }
                    return;
                }
            } catch (error) {
                reject(new Error(error));
            }
        });   
    }

    handleNewPassword = async (newPassword, attributesObj, Session) => {
        //TODO check if we have all the required attributes.
        const response = await this.userPoolClient.send(new RespondToAuthChallengeCommand({
            ChallengeName: 'NEW_PASSWORD_REQUIRED',
            ChallengeResponses: {
                NEW_PASSWORD: newPassword,
                USERNAME: attributesObj.email,
                ...attributesObj
            },
            ClientId: poolData.ClientId,
            Session
        }));

        this._saveTokens(response.AuthenticationResult);
    }

    logout = () => {
        this._removeTokens();
    }
}


export const AuthService = new Authentication();