import BaseAPIService from "../baseAPIService/BaseAPIService";
import { AuthService } from "../auth/auth";

const ALLOWED_IMAGE_EXTENSIONS = [
    "jpg",
    "jpeg",
    "jfif",
    "pjpeg",
    "png",
    "webp"
]

class FileServiceClass extends BaseAPIService {

    getFileExtension = (fileNameWithExtension) => {
        return fileNameWithExtension.split('.').pop(); //TODO adapt to look for double extensions i.e. .tar.gz
    }

    getFileName = (fileNameWithExtension) => {
        //TODO only allow valid caracters 
        //Alphanumeric characters: 0-9, a-z, A-Z
        //Special characters: !, -, _, ., *, ', (, )
        //TODO accept files with dots in the name i.e. Screenshot 16.02.2022
        return fileNameWithExtension.split('.')[0];
    }


    onUploadProgress = (subscribedFunction) => {
        this.uploadProgressHandler = subscribedFunction;
    }

    _emitUploadProgress = (loaded, total) => {
        const percent = Math.ceil(loaded / total * 100);
        if (this.uploadProgressHandler) {
            this.uploadProgressHandler(percent);
        }
    }

    uploadImage = async (file, uploadType) => {
        const fileNameWithExtension = file.name || file.split(/(\\|\/)/g).pop()
        ;
        const extension = this.getFileExtension(fileNameWithExtension);

        if (!ALLOWED_IMAGE_EXTENSIONS.includes(extension)) {
            return false;
        }
        const fileName = this.getFileName(fileNameWithExtension);
        try {
            const response = await this.getUploadLinkForFile(fileName, extension, file.type, uploadType)
            const uploadLink = response.url;
            this._emitUploadProgress(1, 100);

            const uploadResponse = await this.axios({
                method: 'PUT',
                url: uploadLink,
                headers: {
                    'Content-Type': file.type
                },
                onUploadProgress: ({loaded, total}) => {
                    if (!loaded || !total) return;
                    this._emitUploadProgress(loaded, total);
                },
                transformRequest: [(data, headers) => {
                    delete headers.common.Authorization ;
                    return data 
                }],
                data: file,
                timeout: 1000 * 60 * 2
            });
            console.log("file: FileService.js ~ line 44 ~ FileServiceClass ~ uploadImage= ~ uploadResponse", uploadResponse);
        } catch (error) {
            console.log("file: FileService.js ~ line 36 ~ FileServiceClass ~ uploadImage= ~ error", error);
            throw new Error(error);
        }
    }
    getUploadLinkForFile = async (fileName, extension, contentType, uploadType) => {
        const tokens = await AuthService.getLoggedInUserTokens();
        if (!tokens) return;
        
        const {IdToken} = tokens;
        await this._preRequest();

        const response = await this.axios({
            method: 'GET',
            url: '/upload/link',
            headers: {
                "pmi-idt": IdToken,
                "pmi-file-name": fileName,
                "pmi-file-ext": extension,
                "pmi-file-content-type": contentType,
                "pmi-file-upload-type": uploadType
            }
        });
        console.log("file: FileService.js ~ line 70 ~ FileServiceClass ~ getUploadLinkForFile= ~ contentType", contentType);

        return response.data.data
    }

    getAllImages = async () => {
        await this._preRequest();

        const response = await this.axios({
            method: 'GET',
            url: 'uploaded/images'
        })

        return response.data.data
    }

    getAllImageIcons = async () => {
        await this._preRequest();

        const response = await this.axios({
            method: 'GET',
            url: 'uploaded/template-icons'
        });

        return response.data.data;
    }
    
}

export const FileService = new FileServiceClass();