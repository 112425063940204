import axios from 'axios';
import { AuthService } from '../auth/auth';

class Config {
    gatewayId: string | undefined;
    gatewayENV: string | undefined;
    initialized: boolean;
    config: object | undefined;
    constructor() {
        console.log(process.env);
        this.gatewayId = process.env.REACT_APP_CONFIG_GATEWAY_ID;
        this.gatewayENV = process.env.REACT_APP_CONFIG_GATEWAY_ENV;
        console.log("file: ConfigurationService.ts ~ line 13 ~ Config ~ constructor ~ process.env.REACT_APP_CONFIG_GATEWAY_ENV", process.env.REACT_APP_CONFIG_GATEWAY_ENV);

        this.initialized = false;
    }


    init = async () => {
        if (this.initialized) return ;
        this.config = await this.getConfig();
        this.initialized = true;
    }

    getConfig = async () => {
        if (this.config) return this.config;

        const tokens = await AuthService.getLoggedInUserTokens();
        const configURL = `https://${this.gatewayId}.execute-api.eu-central-1.amazonaws.com/${this.gatewayENV}/app/config`

        const response = await axios({
            method: 'GET',
            url: configURL,
            headers: {
                Authorization: tokens.AccessToken
            }
        });
        console.log("file: ConfigurationService.ts ~ line 31 ~ Config ~ getConfig= ~ response", response);

        return response.data.data;
    }
}


export const ConfigurationService = new Config();